import client from './client';

const garmin = {
  login: (username: string, password: string) => 
    client.post('/garmin/login', { username, password }),
  
  checkStatus: () => 
    client.get('/garmin/login/status'),
  
  submitMfa: (code: string) => 
    client.post('/garmin/login/mfa', { code }),
};


const api = {
  garmin,
  
  users: {
    getCurrentUser: () => 
      client.get('/me').then(response => response.data),
  },

  activities: {
    getPastActivities: () => 
      client.get('/activities').then(response => response.data),
      
    getPlannedActivities: () => 
      client.get('/planned').then(response => response.data),
      
    syncActivities: () => 
      client.post('/sync-activities').then(response => response.data),
      
    regenerateActivity: (activityId: string, reason: string) => 
      client.post(`/activity/${activityId}/regenerate`, { reason })
        .then(response => response.data),
        
    markActivitySkipped: (activityId: string) => 
      client.post(`/activity/${activityId}/mark-skipped`)
        .then(response => response.data),
  },

  goals: {
    postGoal: (goal: any) => 
      client.post('/goals', goal).then(response => response.data),
      
    getGoals: () => 
      client.get('/goals').then(response => response.data),
      
    deleteGoal: (goalId: string) => 
      client.delete(`/goals/${goalId}`).then(response => response.data),
  },

  weekPlan: {
    getWeekPlan: () => 
      client.get('/week-plan').then(response => response.data),
      
    submitWeekFeedback: (weekId: string, feedback: string) => 
      client.post(`/week/${weekId}/feedback`, { feedback })
        .then(response => response.data),
  },

  settings: {
    getTrainingDays: () => 
      client.get('/training-days').then(response => response.data),
      
    updateTrainingDays: (trainingDays: number[]) => 
      client.post('/training-days', { trainingDays })
        .then(response => response.data),
  },

  predictions: {
    getRacePredictions: () => 
      client.get('/race-predictor').then(response => response.data),
  },
};

export { garmin, api }; 
