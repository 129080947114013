import { useQuery } from '@tanstack/react-query';
import { api } from '../services/api.ts';

export interface User {
  email: string;
  hasCompletedSetup: boolean;
  garminConnection?: {
    status: string;
  };
}

export function useUser() {
  return useQuery<User>({
    queryKey: ['user'],
    queryFn: () => api.users.getCurrentUser(),
    staleTime: 1000 * 60 * 5,
  });
} 
