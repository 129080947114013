import React, { useState } from 'react';
import html2canvas from 'html2canvas';
import Modal from '../../shared/Modal.tsx';

interface ShareButtonProps {
  onBeforeShare?: () => void;
  onAfterShare?: () => void;
}

const ShareButton: React.FC<ShareButtonProps> = ({ onBeforeShare, onAfterShare }) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [imageData, setImageData] = useState<string | null>(null);

  const handleShare = async () => {
    if (isGenerating) return;
    setIsGenerating(true);
    onBeforeShare?.();
    
    try {
      const element = document.getElementById('shareable-activity');
      if (!element) {
        console.error('Could not find shareable activity element');
        return;
      }

      const canvas = await html2canvas(element, {
        scale: 2,
        backgroundColor: '#fff',
        logging: true,
        useCORS: true,
        allowTaint: true,
        foreignObjectRendering: true,
        removeContainer: true,
        width: 1080,
        height: 1080,
        onclone: (document) => {
          // Force all images to be loaded
          const images = document.getElementsByTagName('img');
          for (let i = 0; i < images.length; i++) {
            images[i].crossOrigin = 'anonymous';
          }
        }
      });

      const dataUrl = canvas.toDataURL('image/png');
      setImageData(dataUrl);

    } catch (error) {
      console.error('Failed to generate image:', error);
    } finally {
      setIsGenerating(false);
      onAfterShare?.();
    }
  };

  return (
    <>
      <button
        onClick={handleShare}
        disabled={isGenerating}
        className={`inline-flex items-center space-x-2 px-4 py-2 rounded-xl transition-colors
          ${isGenerating 
            ? 'bg-white/20 cursor-not-allowed' 
            : 'bg-white/10 hover:bg-white/20'
          } text-white`}
      >
        {isGenerating ? (
          <>
            <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
            </svg>
            <span>Generating...</span>
          </>
        ) : (
          <>
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z" />
            </svg>
            <span>Share Activity</span>
          </>
        )}
      </button>
      {imageData && (
        <Modal isOpen={true} onClose={() => setImageData(null)}>
          <img src={imageData} alt="Shared Activity" className="max-w-full max-h-full" />
        </Modal>
      )}
    </>
  );
};

export default ShareButton; 