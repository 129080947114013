import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '../services/api.ts';
import { Goal } from '../types/goal';

export function useGoals() {
  const queryClient = useQueryClient();

  const query = useQuery<Goal[]>({
    queryKey: ['goals'],
    queryFn: () => api.goals.getGoals(),
    staleTime: 1000 * 60 * 5,
  });

  const addGoal = useMutation({
    mutationFn: (goal: Goal) => api.goals.postGoal(goal),
    onSuccess: () => queryClient.invalidateQueries(['goals']),
  });

  const removeGoal = useMutation({
    mutationFn: (goalId: string) => api.goals.deleteGoal(goalId),
    onSuccess: () => queryClient.invalidateQueries(['goals']),
  });

  return {
    ...query,
    addGoal: addGoal.mutateAsync,
    removeGoal: removeGoal.mutateAsync,
  };
}
