import { useEffect } from 'react';
import { authService } from '../services/auth.service.ts';
import stretchingRonny from '../assets/coach/stretching.png';

const LogoutPage = () => {
  useEffect(() => {
    const performLogout = async () => {
      try {
        await authService.logout();
      } catch (error) {
        console.error('Logout failed:', error);
        window.location.href = '/login';
      }
    };
    
    performLogout();
  }, []);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-orange-500 to-orange-400 p-6">
      <div className="w-full max-w-md flex flex-col items-center mb-8">
        <div className="relative w-40 h-40 mb-2">
          <img
            src={stretchingRonny}
            alt="Ronny Stretching"
            className="w-full h-full object-contain drop-shadow-xl animate-stretch"
            style={{
              transformOrigin: 'bottom center',
              animation: 'stretch 3s ease-in-out infinite'
            }}
          />
        </div>
        <h2 className="text-2xl font-bold text-white mb-4">Logging out...</h2>
        <div className="w-16 h-16">
          <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-white"></div>
        </div>
      </div>
    </div>
  );
};

export default LogoutPage;
