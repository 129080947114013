import React, { useState } from 'react';
import { useRacePredictions } from '../../../hooks/useRacePredictions.ts';
import { formatSecondsToTime } from '../../../utils/timeUtils.ts';

const predictions = [
  { key: 'time5K', label: '5K', icon: '🏃' },
  { key: 'time10K', label: '10K', icon: '🏃‍♂️' },
  { key: 'timeHalfMarathon', label: 'Half', icon: '🎽' },
  { key: 'timeMarathon', label: 'Marathon', icon: '🏁' },
];

const RacePredictions = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { data: racePredictions, isLoading } = useRacePredictions();

  const handleNext = () => {
    setCurrentIndex((prev) => (prev + 1) % predictions.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prev) => (prev - 1 + predictions.length) % predictions.length);
  };

  if (isLoading) {
    return (
      <div className="bg-white rounded-xl shadow-sm border border-orange-100 p-4 hover:shadow-md transition-shadow animate-pulse">
        <div className="text-sm font-medium text-gray-600 mb-1">Race Prediction</div>
        <div className="flex items-center justify-between">
          <button className="absolute left-2 p-1 opacity-0">←</button>
          <div className="flex-1 text-center">
            <div className="h-7 w-24 bg-gray-200 rounded-lg mx-auto mb-2" />
            <div className="h-8 w-32 bg-orange-100 rounded-lg mx-auto" />
          </div>
          <button className="absolute right-2 p-1 opacity-0">→</button>
        </div>
      </div>
    );
  }

  if (!racePredictions) return null;

  const currentPrediction = predictions[currentIndex];
  const time = racePredictions[currentPrediction.key as keyof typeof racePredictions];

  return (
    <div className="bg-white rounded-xl shadow-sm border border-orange-100 p-4 hover:shadow-md transition-shadow relative">
      <div className="text-sm font-medium text-gray-600 mb-1">Race Prediction</div>
      <div className="flex items-center justify-between">
        <button 
          onClick={handlePrev}
          className="absolute left-2 p-1 text-gray-400 hover:text-orange-600"
        >
          ←
        </button>
        <div className="flex-1 text-center">
          <div className="text-xl mb-0.5">
            {currentPrediction.icon} {currentPrediction.label}
          </div>
          <div className="text-2xl font-bold text-orange-600">
            {formatSecondsToTime(time)}
          </div>
        </div>
        <button 
          onClick={handleNext}
          className="absolute right-2 p-1 text-gray-400 hover:text-orange-600"
        >
          →
        </button>
      </div>
    </div>
  );
};

export default RacePredictions; 