import {useQuery} from "@tanstack/react-query";
import { api } from '../services/api.ts';
import { CompletedActivity, PlannedActivity } from '../types/activity';

export function usePlannedActivities() {
    return useQuery<PlannedActivity[]>({
        queryKey: ['planned-activities'],
        queryFn: () => api.activities.getPlannedActivities(),
    });
}

export function useCompletedActivities() {
    return useQuery<CompletedActivity[]>({
        queryKey: ['completed-activities'],
        queryFn: () => api.activities.getPastActivities(),
    });
}
