import React, { useState, useEffect } from 'react';
import BottomNav from '../components/shared/BottomNav.tsx';
import { useNavigate } from 'react-router-dom';
import ConfirmDialog from '../components/shared/ConfirmDialog.tsx';
import SectionHeader from '../components/shared/SectionHeader.tsx';
import {useGoals} from "../hooks/useGoals.ts";
import {useTrainingDays} from "../hooks/useTrainingDays.ts";
import {useUser} from "../hooks/useUser.ts";
import PageLayout from "../components/shared/PageLayout.tsx";
import GoalList from "../components/Goals/GoalList.tsx";

interface DayMap {
  day: string;
  value: number;
}

const SettingsPage: React.FC = () => {
  const navigate = useNavigate();
  const { data: goals, removeGoal } = useGoals();
  const { data: savedTrainingDays, updateTrainingDays } = useTrainingDays();
  const { data: userDetails } = useUser();

  console.log('Saved training days from API:', savedTrainingDays);

  const dayMap: DayMap[] = [
    { day: 'Monday', value: 1 },
    { day: 'Tuesday', value: 2 },
    { day: 'Wednesday', value: 3 },
    { day: 'Thursday', value: 4 },
    { day: 'Friday', value: 5 },
    { day: 'Saturday', value: 6 },
    { day: 'Sunday', value: 7 },
  ];

  const [selectedDays, setSelectedDays] = useState<number[]>(() => {
    if (!savedTrainingDays?.trainingDays) return [];
    return savedTrainingDays.trainingDays;
  });

  const [longRunDay, setLongRunDay] = useState<number>(() => {
    return savedTrainingDays?.longRunDay || 6;
  });

  useEffect(() => {
    if (savedTrainingDays) {
      setSelectedDays(savedTrainingDays.trainingDays);
      setLongRunDay(savedTrainingDays.longRunDay);
    }
  }, [savedTrainingDays]);

  const [isSaving, setIsSaving] = useState(false);
  const [goalToDelete, setGoalToDelete] = useState<string | null>(null);

  const findClosestTrainingDay = (currentDay: number, availableDays: number[]): number => {
    if (availableDays.includes(currentDay)) return currentDay;
    if (availableDays.length === 0) return currentDay;
    
    return availableDays.reduce((closest, day) => {
      const currentDiff = Math.abs(day - currentDay);
      const closestDiff = Math.abs(closest - currentDay);
      return currentDiff < closestDiff ? day : closest;
    }, availableDays[0]);
  };

  const handleDayToggle = (dayValue: number) => {
    setSelectedDays(prev => {
      const newDays = prev.includes(dayValue)
        ? prev.filter(d => d !== dayValue)
        : [...prev, dayValue].sort((a, b) => a - b);
      
      if (dayValue === longRunDay && !newDays.includes(dayValue)) {
        setLongRunDay(findClosestTrainingDay(longRunDay, newDays));
      }
      
      return newDays;
    });
  };

  const handleSaveTrainingDays = async () => {
    setIsSaving(true);
    try {
      await updateTrainingDays({
        trainingDays: selectedDays,
        longRunDay
      });
    } catch (error) {
      console.error('Failed to save training days:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDeleteGoal = async () => {
    if (goalToDelete) {
      try {
        await removeGoal(goalToDelete);
        setGoalToDelete(null); // Close dialog after successful deletion
      } catch (error) {
        // Handle error (maybe show a toast)
        console.error('Failed to delete goal:', error);
      }
    }
  };

  const LongRunDaySelector = () => (
    <div className="mt-8 space-y-4">
      <div className="flex items-center justify-between">
        <h3 className="text-sm font-medium text-gray-700">Long Run Day</h3>
        <span className="text-xs text-gray-500">
          Select your preferred day for long runs
        </span>
      </div>
      
      <div className="relative">
        <select
          value={longRunDay}
          onChange={(e) => setLongRunDay(Number(e.target.value))}
          className="w-full px-4 py-3 pr-10 rounded-xl border border-gray-200 
                   focus:border-orange-500 focus:ring-1 focus:ring-orange-500
                   bg-white appearance-none cursor-pointer"
        >
          {dayMap.map(({ day, value }) => (
            <option 
              key={day} 
              value={value}
              disabled={!selectedDays.includes(value)}
            >
              {day} {!selectedDays.includes(value) ? '(not a training day)' : ''}
            </option>
          ))}
        </select>
        
        {/* Custom dropdown arrow */}
        <div className="absolute inset-y-0 right-0 flex items-center px-4 pointer-events-none">
          <svg 
            className="w-5 h-5 text-gray-400" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M19 9l-7 7-7-7" 
            />
          </svg>
        </div>
      </div>
    </div>
  );

  return (
    <PageLayout title="Settings">
      <div className="space-y-6 pb-20">
        {/* Profile Section */}
        <div className="bg-gradient-to-br from-orange-500 to-orange-600 rounded-2xl shadow-lg text-white p-6">
          <div className="flex items-center space-x-4">
            <div className="w-16 h-16 bg-white/20 rounded-full flex items-center justify-center text-2xl">
              {userDetails?.username?.charAt(0).toUpperCase() || '👤'}
            </div>
            <div>
              <h2 className="text-xl font-semibold">{userDetails?.username}</h2>
              <p className="text-white/80">{userDetails?.email}</p>
            </div>
          </div>
        </div>

        {/* Connected Services */}
        <div className="bg-white rounded-2xl shadow-sm overflow-hidden border border-gray-100">
          <SectionHeader
            title="Connected Services"
            badge={{
              text: userDetails?.garminConnection?.status === 'connected' ? 'Connected' : 'Not Connected',
              color: userDetails?.garminConnection?.status === 'connected' ? 'green' : 'gray'
            }}
          />
          <div className="p-6">
            <div className="flex items-center justify-between p-4 bg-gray-50 rounded-xl">
              <div className="flex items-center space-x-3">
                <div className="p-2 bg-blue-100 rounded-lg">
                  <svg className="w-5 h-5 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                      d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
                    />
                  </svg>
                </div>
                <div>
                  <div className="font-medium text-gray-900">Garmin Connect</div>
                  <div className="text-sm text-gray-500">
                    {userDetails?.garminConnection?.status === 'connected' ? (
                      'Connected'
                    ) : userDetails?.garminConnection?.status === 'in_progress' || 
                       userDetails?.garminConnection?.status === 'mfa_submitted' ? (
                      'Connecting...'
                    ) : (
                      'Not connected'
                    )}
                  </div>
                </div>
              </div>
              {userDetails?.garminConnection?.status === 'connected' ? (
                <div className="flex items-center">
                  <div className="w-2 h-2 bg-green-500 rounded-full mr-2"></div>
                  <span className="text-sm text-green-600 font-medium">Active</span>
                </div>
              ) : (
                <button
                  onClick={() => navigate('/connect-garmin')}
                  className="px-4 py-2 bg-gradient-to-r from-blue-500 to-blue-600 
                           hover:from-blue-600 hover:to-blue-700 text-white rounded-lg 
                           text-sm font-medium transition-all duration-200 shadow-sm hover:shadow"
                >
                  Connect
                </button>
              )}
            </div>
          </div>
        </div>

        {/* Training Goals Section */}
        <div className="bg-white rounded-2xl shadow-sm overflow-hidden border border-orange-100">
          <SectionHeader
            title="Training Goals"
            badge={{
              text: `${goals?.length || 0} goals`,
              color: 'orange'
            }}
          />
          <div className="divide-y divide-gray-100">
            <GoalList />
            <div className="p-4">
              <button
                onClick={() => navigate('/add-goal')}
                className="w-full py-2.5 px-4 bg-gradient-to-r from-orange-500 to-orange-600
                         hover:from-orange-600 hover:to-orange-700 text-white rounded-xl
                         font-medium transition-all duration-200 shadow-sm hover:shadow"
              >
                Add New Goal
              </button>
            </div>
          </div>
        </div>

        {/* Training Days Section */}
        <div className="bg-white rounded-2xl shadow-sm overflow-hidden border border-orange-100">
          <SectionHeader
            title="Training Days"
            badge={{
              text: `${selectedDays.length} active days`,
              color: 'orange'
            }}
          />
          <div className="p-6">
            <div className="space-y-3">
              {dayMap.map(({ day, value }) => (
                <label 
                  key={day}
                  className={`
                    flex items-center justify-between p-4 
                    backdrop-blur-sm rounded-xl cursor-pointer 
                    transition-all duration-200 border 
                    ${selectedDays.includes(value)
                      ? 'bg-orange-50/50 border-orange-100 hover:bg-orange-50/80' 
                      : 'bg-gray-50/50 border-gray-100 hover:bg-gray-50/80'
                    }
                  `}
                >
                  <div className="flex items-center space-x-3">
                    <div className={`
                      w-8 h-8 rounded-full flex items-center justify-center
                      transition-colors duration-200
                      ${selectedDays.includes(value)
                        ? 'bg-gradient-to-r from-orange-500 to-orange-600 text-white' 
                        : 'bg-gray-100 text-gray-400'
                      }
                    `}>
                      {day.charAt(0)}
                    </div>
                    <span className={`
                      font-medium transition-colors duration-200
                      ${selectedDays.includes(value) ? 'text-orange-900' : 'text-gray-500'}
                    `}>
                      {day}
                    </span>
                  </div>

                  <div className="relative">
                    <input
                      type="checkbox"
                      checked={selectedDays.includes(value)}
                      onChange={() => handleDayToggle(value)}
                      className="sr-only"
                    />
                    <div className={`
                      w-12 h-7 rounded-full 
                      transition-all duration-300 ease-in-out
                      ${selectedDays.includes(value)
                        ? 'bg-gradient-to-r from-orange-500 to-orange-600' 
                        : 'bg-gray-200'
                      }
                      shadow-inner
                    `}>
                      <div className={`
                        w-5 h-5 rounded-full bg-white 
                        shadow-sm transform transition-transform duration-300 ease-in-out
                        absolute top-1 
                        ${selectedDays.includes(value) ? 'translate-x-6' : 'translate-x-1'}
                      `} />
                    </div>
                  </div>
                </label>
              ))}

              <LongRunDaySelector />

              <button
                onClick={handleSaveTrainingDays}
                disabled={isSaving}
                className={`
                  w-full mt-6 py-3.5 px-4 rounded-xl font-medium 
                  text-white transition-all duration-200 
                  shadow-sm hover:shadow flex items-center justify-center space-x-2
                  ${isSaving 
                    ? 'bg-gray-400 cursor-not-allowed' 
                    : 'bg-gradient-to-r from-orange-500 to-orange-600 hover:from-orange-600 hover:to-orange-700'
                  }
                `}
              >
                {isSaving ? (
                  <>
                    <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    <span>Saving...</span>
                  </>
                ) : (
                  <>
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span>Save Training Days</span>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      <ConfirmDialog
        isOpen={!!goalToDelete}
        onClose={() => setGoalToDelete(null)}
        onConfirm={handleDeleteGoal}
        title="Delete Goal"
        message="Are you sure you want to delete this goal? This action cannot be undone."
      />

      <BottomNav />
    </PageLayout>
  );
};

export default SettingsPage; 
