import React, { useState, useEffect } from 'react';
import PageLayout from '../components/shared/PageLayout.tsx';
import { garmin } from '../services/api.ts';
import {useUser} from "../hooks/useUser.ts";

type LoginStatus = 
  | 'not_connected'  // Initial state
  | 'connected'      // Successfully connected
  | 'mfa_required'   // MFA input needed
  | 'mfa_submitted'  // MFA is pending verification
  | 'in_progress'    // Waiting for backend process
  | 'failed'         // Connection failed
  | 'loading';       // For UI purposes

const ConnectGarminPage: React.FC = () => {
  const [status, setStatus] = useState<LoginStatus>('loading');
  const [error, setError] = useState<string | null>(null);
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [mfaCode, setMfaCode] = useState('');
  const [pollInterval, setPollInterval] = useState<NodeJS.Timeout | null>(null);
  const { data: userDetails } = useUser();

  useEffect(() => {
    if (userDetails?.garminConnection?.status) {
      setStatus(userDetails.garminConnection.status);
    } else {
      setStatus('not_connected');
    }
  }, [userDetails]);

  useEffect(() => {
    return () => {
      if (pollInterval) clearInterval(pollInterval);
    };
  }, [pollInterval]);

  const pollStatus = () => {
    if (pollInterval) clearInterval(pollInterval);

    const interval = setInterval(async () => {
      try {
        const response = await garmin.checkStatus();

        switch (response.data.status) {
          case 'connected':
            setStatus('connected');
            clearInterval(interval);
            break;
          case 'mfa_required':
            setStatus('mfa_required');
            clearInterval(interval);
            break;
          case 'mfa_submitted':
            setStatus('mfa_submitted');
            break;
          case 'in_progress':
            setStatus('in_progress');
            break;
          case 'failed':
            setStatus('failed');
            setError('Connection failed. Please try again.');
            clearInterval(interval);
            break;
          case 'not_connected':
            setStatus('not_connected');
            clearInterval(interval);
            break;
        }
      } catch (err) {
        setError('Failed to check connection status');
        setStatus('failed');
        clearInterval(interval);
      }
    }, 2000);

    setPollInterval(interval);
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setStatus('loading');

    try {
      await garmin.login(credentials.username, credentials.password);
      setStatus('in_progress');
      pollStatus();
    } catch (err) {
      setError('Login failed. Please check your credentials.');
      setStatus('failed');
    }
  };

  const handleMfaSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setStatus('loading');

    try {
      await garmin.submitMfa(mfaCode);
      setStatus('mfa_submitted');
      pollStatus();
    } catch (err) {
      setError('Invalid MFA code');
      setStatus('mfa_required');
    }
  };

  const handleRetry = () => {
    setStatus('not_connected');
    setError(null);
    setCredentials({ username: '', password: '' });
    setMfaCode('');
  };

  return (
    <PageLayout title="Connect Garmin" showBack={true}>
      <div className="space-y-6">
        <div className="bg-white rounded-2xl shadow-sm overflow-hidden border border-gray-100">
          <div className="p-6">
            {/* Header */}
            <div className="flex items-center justify-center space-x-4 mb-6">
              <div className="p-3 bg-blue-100 rounded-full">
                <svg className="w-8 h-8 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                    d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
                  />
                </svg>
              </div>
              <h2 className="text-2xl font-bold text-gray-900">Connect Your Garmin Account</h2>
            </div>

            {/* Error Message */}
            {error && (
              <div className="mb-4 p-4 bg-red-50 border border-red-100 rounded-xl text-red-600 text-sm">
                {error}
                {status === 'failed' && (
                  <button
                    onClick={handleRetry}
                    className="ml-2 text-red-700 hover:text-red-800 font-medium"
                  >
                    Try Again
                  </button>
                )}
              </div>
            )}

            {/* Success Message */}
            {status === 'connected' && (
              <div className="text-center space-y-4">
                <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto">
                  <svg className="w-8 h-8 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                </div>
                <p className="text-lg font-medium text-gray-900">Successfully Connected!</p>
                <p className="text-gray-600">Your Garmin account is now linked.</p>
              </div>
            )}

            {/* Loading States */}
            {(status === 'loading' || status === 'in_progress' || status === 'mfa_submitted') && (
              <div className="text-center space-y-4">
                <div className="w-16 h-16 mx-auto">
                  <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-blue-500"></div>
                </div>
                <p className="text-gray-600">
                  {status === 'mfa_submitted' ? 'Verifying MFA code...' : 
                   status === 'in_progress' ? 'Connecting to Garmin...' :
                   'Processing...'}
                </p>
              </div>
            )}

            {/* Login Form */}
            {(status === 'not_connected' || status === 'failed') && (
              <form onSubmit={handleLogin} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Username
                  </label>
                  <input
                    type="text"
                    value={credentials.username}
                    onChange={(e) => setCredentials(prev => ({ ...prev, username: e.target.value }))}
                    className="w-full px-4 py-2 rounded-xl border border-gray-200"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Password
                  </label>
                  <input
                    type="password"
                    value={credentials.password}
                    onChange={(e) => setCredentials(prev => ({ ...prev, password: e.target.value }))}
                    className="w-full px-4 py-2 rounded-xl border border-gray-200"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="w-full py-3 px-4 bg-gradient-to-r from-blue-500 to-blue-600 
                           hover:from-blue-600 hover:to-blue-700 text-white rounded-xl 
                           font-medium transition-all duration-200 shadow-sm hover:shadow"
                >
                  Connect Account
                </button>
              </form>
            )}

            {/* MFA Form */}
            {status === 'mfa_required' && (
              <form onSubmit={handleMfaSubmit} className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Enter MFA Code
                  </label>
                  <input
                    type="text"
                    value={mfaCode}
                    onChange={(e) => setMfaCode(e.target.value)}
                    className="w-full px-4 py-2 rounded-xl border border-gray-200"
                    placeholder="Enter the code from your authenticator app"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="w-full py-3 px-4 bg-gradient-to-r from-blue-500 to-blue-600 
                           hover:from-blue-600 hover:to-blue-700 text-white rounded-xl 
                           font-medium transition-all duration-200 shadow-sm hover:shadow"
                >
                  Submit Code
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default ConnectGarminPage; 
