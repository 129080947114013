import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePlannedActivities } from '../../hooks/UseActivities.ts';

const Notifications: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { data: plannedActivities } = usePlannedActivities();

  // Find past activities that are not completed or skipped
  const incompleteActivities = plannedActivities?.filter(activity => {
    const activityDate = new Date(activity.date);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    
    return activityDate < today && 
           activity.status !== 'completed' && 
           activity.status !== 'skipped';
  }) || [];

  const hasNotifications = incompleteActivities.length > 0;

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="relative p-2 text-gray-600 hover:text-orange-600 transition-colors"
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" 
          />
        </svg>
        
        {/* Notification badge */}
        {hasNotifications && (
          <span className="absolute top-1 right-1 w-2 h-2 bg-red-500 rounded-full" />
        )}
      </button>

      {/* Dropdown */}
      {isOpen && (
        <div className="absolute right-0 mt-2 w-80 bg-white rounded-xl shadow-lg border border-gray-100 overflow-hidden z-50">
          <div className="p-3 bg-gray-50 border-b border-gray-100">
            <h3 className="font-medium text-gray-900">Notifications</h3>
          </div>
          
          <div className="divide-y divide-gray-100 max-h-96 overflow-auto">
            {incompleteActivities.length > 0 ? (
              incompleteActivities.map(activity => (
                <div 
                  key={activity.id}
                  className="p-4 hover:bg-gray-50 cursor-pointer"
                  onClick={() => {
                    navigate(`/activities/${activity.date}`);
                    setIsOpen(false);
                  }}
                >
                  <div className="flex items-start space-x-3">
                    <div className="p-1.5 bg-orange-100 rounded-lg text-orange-600">
                      <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
                          d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" 
                        />
                      </svg>
                    </div>
                    <div>
                      <p className="text-sm text-gray-900">
                        Incomplete Activity
                      </p>
                      <p className="text-xs text-gray-500 mt-0.5">
                        {new Date(activity.date).toLocaleDateString()} - {activity.type}
                      </p>
                      <p className="text-xs text-orange-600 font-medium mt-1">
                        Click to mark as skipped or sync
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="p-4 text-center text-sm text-gray-500">
                No notifications
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Notifications; 