import client from './client';

class AuthService {
    async login(username: string, password: string, remember_me: boolean) {
        await client.post('/login', { username, password, remember_me });
    }

    async logout() {
        console.log("Logging out");
        await client.post('/logout');
        window.location.href = '/login';
    }
}

export const authService = new AuthService();
