import React, { useState, useEffect } from 'react';
import PageLayout from '../components/shared/PageLayout.tsx';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import ronnyThinking from '../assets/coach/sleeping.png';
import ronnyWorking from '../assets/coach/running.png';
import { useNavigate } from 'react-router-dom';
import {useWeekPlan, WeekPlanPhase} from "../hooks/useWeekPlan.ts";
import {useGoals} from "../hooks/useGoals.ts";

const phaseColors: Record<WeekPlanPhase, string> = {
  [WeekPlanPhase.BASE]: '#FBBF24',      // amber-400 (lighter, more yellow)
  [WeekPlanPhase.BUILD]: '#F97316',     // orange-500 (vibrant orange)
  [WeekPlanPhase.PEAK]: '#DC2626',      // red-600 (energetic red)
  [WeekPlanPhase.TAPER]: '#7C3AED',     // violet-600 (contrasting purple)
  [WeekPlanPhase.RACEWEEK]: '#059669',  // emerald-600 (fresh green)
};

// Custom bar component to handle colors
const CustomBar = (props: any) => {
  const { x, y, width, height, payload, selectedWeek } = props;
  const isSelected = selectedWeek?.week_number === payload.weekData.week_number;
  
  return (
    <g>
      {/* Enhanced background glow for selected bar */}
      {isSelected && (
        <>
          {/* Larger outer glow */}
          <rect 
            x={x - 4}
            y={y - 4}
            width={width + 8}
            height={height + 8}
            fill={phaseColors[payload.phase]}
            fillOpacity={0.2}
            rx={8}
            ry={8}
          />
          {/* Inner glow */}
          <rect 
            x={x - 2}
            y={y - 2}
            width={width + 4}
            height={height + 4}
            fill={phaseColors[payload.phase]}
            fillOpacity={0.4}
            rx={6}
            ry={6}
          />
        </>
      )}
      {/* Main bar with enhanced visibility */}
      <rect 
        x={x} 
        y={y} 
        width={width} 
        height={height} 
        fill={phaseColors[payload.phase]} 
        fillOpacity={isSelected ? 1 : 0.85} // Increased base opacity
        stroke={isSelected ? 'white' : 'none'} // White stroke for selected
        strokeWidth={2}
        rx={4}
        ry={4}
      />
    </g>
  );
};

const TrainingPlan = () => {
  const navigate = useNavigate();
  const { data: weekPlan } = useWeekPlan();
  const {data: goals} = useGoals();
  const [selectedWeek, setSelectedWeek] = useState(null);

  // Add this function to find current week
  const getCurrentWeek = () => {
    const now = new Date();
    const currentWeekNumber = getWeekNumber(now);
    const currentYear = now.getFullYear();

    return weekPlan?.find(week => 
      week.week_number === currentWeekNumber && 
      week.year === currentYear
    );
  };

  // Add useEffect to set initial selection
  // eslint-disable-next-line
  useEffect(() => {
    if (weekPlan) {
    // eslint-disable-next-line
      const currentWeek = getCurrentWeek();
      if (currentWeek) {
        setSelectedWeek(currentWeek);
      }
    }
    // eslint-disable-next-line
  }, [weekPlan]);

  // Helper function to get week number
  const getWeekNumber = (date: Date) => {
    const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    const dayNum = d.getUTCDay() || 7;
    d.setUTCDate(d.getUTCDate() + 4 - dayNum);
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    return Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1)/7);
  };

  if (!weekPlan || weekPlan.length === 0) {
    // If user has goals but no plan yet
    if (goals?.length > 0) {
      return (
        <PageLayout title="Training Plan">
          <div className="flex flex-col items-center justify-center space-y-6 text-center">
            <div className="bg-white p-2 rounded-full shadow-md w-24 h-24">
              <img 
                src={ronnyWorking} 
                alt="Ronny working" 
                className="w-full h-full rounded-full animate-pulse"
              />
            </div>
            <div className="max-w-sm space-y-3">
              <h2 className="text-xl font-semibold text-gray-900">
                Creating Your Training Plan
              </h2>
              <p className="text-gray-600">
                I'm working on crafting the perfect training plan based on your goals! 
                Come back in a bit... 🏃‍♂️
              </p>
              <div className="flex justify-center">
                <div className="inline-flex items-center gap-2 text-orange-600">
                  <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                  </svg>
                  <span className="text-sm font-medium">Generating plan...</span>
                </div>
              </div>
            </div>
          </div>
        </PageLayout>
      );
    }

    // If user has no goals yet
    return (
      <PageLayout title="Training Plan">
        <div className="flex flex-col items-center justify-center space-y-6 text-center">
          <div className="bg-white p-2 rounded-full shadow-md w-24 h-24">
            <img 
              src={ronnyThinking} 
              alt="Ronny thinking" 
              className="w-full h-full rounded-full"
            />
          </div>
          <div className="max-w-sm space-y-3">
            <h2 className="text-xl font-semibold text-gray-900">
              No Training Plan Yet
            </h2>
            <p className="text-gray-600">
              Looks like we haven't set up your training plan yet! 
              Let's start by setting some goals together.
            </p>
            <button 
              onClick={() => navigate('/settings')}
              className="inline-flex justify-center rounded-xl bg-orange-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 transition-colors"
            >
              Set Training Goals
            </button>
          </div>
        </div>
      </PageLayout>
    );
  }

  // Sort weeks chronologically
  const sortedWeeks = [...weekPlan].sort((a, b) => {
    if (a.year !== b.year) return a.year - b.year;
    return a.week_number - b.week_number;
  });

  const chartData = sortedWeeks.map(week => ({
    name: week.week_number,
    distance: week.target_distance,
    phase: week.phase,
    weekData: week,
  }));

  const handleBarClick = (data) => {
    if (data && data.activePayload && data.activePayload[0]) {
      setSelectedWeek(data.activePayload[0].payload.weekData);
    }
  };

  return (
    <PageLayout title="Training Plan">
      <div className="space-y-6">
        {/* Chart Section */}
        <div className="bg-white rounded-2xl p-6 shadow-sm border border-orange-100">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-lg font-semibold text-gray-900">Training Progress</h2>
            <div className="text-sm text-orange-600 font-medium">
              Current Week: {getCurrentWeek()?.week_number || '-'}
            </div>
          </div>
          
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart 
                data={chartData} 
                onClick={(data) => {
                  if (data && data.activePayload && data.activePayload.length > 0) {
                    handleBarClick(data);
                  }
                }}
                margin={{ top: 10, right: 10, bottom: 10 }}
              >
                <XAxis 
                  dataKey="name" 
                  tick={{ fontSize: 12 }} 
                  label={{ 
                    value: 'Week Number',
                    position: 'bottom',
                    offset: 0,
                    style: { fontSize: 12 }
                  }}
                />
                <YAxis 
                  tick={{ fontSize: 12 }}
                  label={{ 
                    value: 'Distance (km)', 
                    angle: -90, 
                    offset: 20,
                    position: 'insideLeft',
                    style: { fontSize: 12 }
                  }}
                />
                <Tooltip 
                  content={({ active, payload }) => {
                    if (active && payload && payload.length) {
                      const data = payload[0].payload;
                      return (
                        <div className="bg-white/95 backdrop-blur-sm p-4 rounded-lg shadow-lg border border-gray-100">
                          <div 
                            className="w-full h-1 mb-2 rounded-full"
                            style={{ backgroundColor: phaseColors[data.phase] }}
                          />
                          <p className="font-medium text-gray-900">Week {data.name}</p>
                          <p className="text-sm text-gray-600">{data.phase} Phase</p>
                          <p className="text-sm font-medium text-gray-900 mt-1">{data.distance} km</p>
                        </div>
                      );
                    }
                    return null;
                  }}
                />
                <Bar 
                  dataKey="distance" 
                  shape={<CustomBar selectedWeek={selectedWeek} />}
                  cursor="pointer"
                />
              </BarChart>
            </ResponsiveContainer>
          </div>

          {/* Legend */}
          <div className="mt-8 flex flex-wrap gap-4 justify-center">
            {Object.entries(WeekPlanPhase).map(([key, phase]) => (
              <div key={phase} className="flex items-center space-x-2">
                <div 
                  className="w-3 h-3 rounded-full" 
                  style={{ backgroundColor: phaseColors[phase] }} 
                />
                <span className="text-sm text-gray-600">
                  {phase.charAt(0).toUpperCase() + phase.slice(1).replace('-', ' ')}
                </span>
              </div>
            ))}
          </div>
        </div>

        {/* Selected Week Details */}
        {selectedWeek && (
          <div className="bg-white rounded-2xl p-6 shadow-sm border border-orange-100 space-y-6">
            <div className="flex justify-between items-start">
              <div>
                <h2 className="text-lg font-semibold text-gray-900">
                  Week {selectedWeek.week_number} Details
                </h2>
                <p className="text-sm text-orange-600 font-medium">{selectedWeek.phase} Phase</p>
              </div>
              <div className="text-right">
                <div className="text-2xl font-bold text-orange-600">
                  {selectedWeek.target_distance} km
                </div>
                <div className="text-sm text-gray-500">Target Distance</div>
              </div>
            </div>

            <div className="bg-orange-50/50 rounded-xl p-4">
              <h3 className="text-sm font-medium text-gray-900 mb-2">Focus</h3>
              <p className="text-sm text-gray-600">{selectedWeek.focus}</p>
            </div>

            <div className="space-y-3">
              <h3 className="text-sm font-medium text-gray-900">Key Focus Points</h3>
              <div className="flex flex-wrap gap-3">
                {selectedWeek.keyWorkouts.map((workout, index) => (
                  <div 
                    key={index} 
                    className="flex items-center space-x-2 bg-gradient-to-br from-orange-50 to-orange-100/50 
                             px-4 py-2 rounded-full border border-orange-100/50"
                  >
                    <svg 
                      className="w-4 h-4 text-orange-500" 
                      fill="none" 
                      stroke="currentColor" 
                      viewBox="0 0 24 24"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={2} 
                        d="M13 10V3L4 14h7v7l9-11h-7z"
                      />
                    </svg>
                    <span className="text-sm text-gray-700">{workout}</span>
                  </div>
                ))}
              </div>
            </div>

            {selectedWeek.notes && (
              <div className="bg-orange-50/50 rounded-xl p-4">
                <h3 className="text-sm font-medium text-gray-900 mb-2">Notes</h3>
                <p className="text-sm text-gray-600">{selectedWeek.notes}</p>
              </div>
            )}
          </div>
        )}
      </div>
    </PageLayout>
  );
};

export default TrainingPlan; 
