import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Dashboard from './components/Dashboard/Dashboard.tsx';
import ActivityDetails from './pages/ActivityDetails.js';
import AddGoalPage from './pages/AddGoalPage.tsx';
import SettingsPage from './pages/SettingsPage.tsx';
import TrainingPlan from './pages/TrainingPlan.tsx';
import WeekOverview from './pages/WeekOverview.tsx';
import ConnectGarminPage from './pages/ConnectGarminPage.tsx';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import LoginPage from "./pages/LoginPage.tsx";
import { useUser } from './hooks/useUser.ts';
import LogoutPage from './pages/LogoutPage.tsx';

const queryClient = new QueryClient();

function App() {
  const ProtectedRoute = ({ children }) => {
    const { data: user, isLoading } = useUser();
    
    if (isLoading) {
      return <div>Loading...</div>;
    }
    
    if (!user) {
      return <Navigate to="/login" />;
    }
    
    return children;
  };

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <div className="App overflow-x-hidden">
          <Routes>
            <Route path="/logout" element={<LogoutPage />} />
            <Route 
              path="/" 
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              } 
            />
            <Route 
              path="/activities/:date" 
              element={
                <ProtectedRoute>
                  <ActivityDetails />
                </ProtectedRoute>
              } 
            />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/add-goal" element={<ProtectedRoute><AddGoalPage /></ProtectedRoute>} />
            <Route path="/settings" element={<ProtectedRoute><SettingsPage /></ProtectedRoute>} />
            <Route path="/training" element={<ProtectedRoute><TrainingPlan /></ProtectedRoute>} />
            <Route path="/week/:weekId" element={<ProtectedRoute><WeekOverview /></ProtectedRoute>} />
            <Route path="/connect-garmin" element={<ProtectedRoute><ConnectGarminPage /></ProtectedRoute>} /> 
          </Routes>
        </div>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;
