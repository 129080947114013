import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import GoalForm from '../components/Goals/GoalForm.tsx';
import coachImage from '../assets/coach/thumbs_up.png';
import Header from '../components/shared/Header.tsx';
import BottomNav from '../components/shared/BottomNav.tsx';
import SectionHeader from '../components/shared/SectionHeader.tsx';
import {useGoals} from "../hooks/useGoals.ts";

const AddGoalPage: React.FC = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [goalType, setGoalType] = useState<'primary' | 'support'>('support');
  const { addGoal: postGoal } = useGoals();
  const navigate = useNavigate();

  const handleSubmit = async (goal: Goal) => {
    try {
      setIsSubmitting(true);
      await postGoal({ ...goal, type: goalType });
      navigate('/settings', { 
        state: { 
          message: 'Goal created successfully!',
          type: 'success'
        } 
      });
    } catch (error) {
      console.error('Error creating goal:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <Header title="Add Goal" showBack={true} />
      
      <main className="container mx-auto max-w-3xl px-4">
        <div className="pt-20 pb-20 sm:pb-6 space-y-6">
          <div className="bg-white/80 backdrop-blur-sm rounded-2xl shadow-lg overflow-hidden border border-gray-100">
            <SectionHeader
              title="Create New Goal"
              badge={{
                text: goalType === 'primary' ? "Race Goal" : "Training Goal",
                color: goalType === 'primary' ? "orange" : "blue"
              }}
              action={
                <div className="bg-white p-1 rounded-full shadow-md">
                  <img 
                    src={coachImage} 
                    alt="Coach" 
                    className="w-8 h-8 rounded-full"
                  />
                </div>
              }
            />
            
            <div className="p-6 space-y-6">
              {/* Goal Type Selection */}
              <div className="grid grid-cols-2 gap-4">
                <button
                  type="button"
                  onClick={() => setGoalType('primary')}
                  className={`p-4 rounded-xl border transition-all duration-200 flex flex-col items-center space-y-2
                    ${goalType === 'primary' 
                      ? 'bg-orange-50 border-orange-200 shadow-sm' 
                      : 'bg-white border-gray-200 hover:bg-orange-50/50'
                    }`}
                >
                  <div className={`w-12 h-12 rounded-full flex items-center justify-center
                    ${goalType === 'primary' 
                      ? 'bg-gradient-to-r from-orange-500 to-orange-600 text-white'
                      : 'bg-gray-100 text-gray-400'
                    }`}
                  >
                    🎯
                  </div>
                  <span className={`font-medium ${goalType === 'primary' ? 'text-orange-900' : 'text-gray-500'}`}>
                    Race Goal
                  </span>
                  <span className="text-xs text-gray-500 text-center">
                    Your main event or race
                  </span>
                </button>

                <button
                  type="button"
                  onClick={() => setGoalType('support')}
                  className={`p-4 rounded-xl border transition-all duration-200 flex flex-col items-center space-y-2
                    ${goalType === 'support' 
                      ? 'bg-blue-50 border-blue-200 shadow-sm' 
                      : 'bg-white border-gray-200 hover:bg-blue-50/50'
                    }`}
                >
                  <div className={`w-12 h-12 rounded-full flex items-center justify-center
                    ${goalType === 'support' 
                      ? 'bg-gradient-to-r from-blue-500 to-blue-600 text-white'
                      : 'bg-gray-100 text-gray-400'
                    }`}
                  >
                    🎪
                  </div>
                  <span className={`font-medium ${goalType === 'support' ? 'text-blue-900' : 'text-gray-500'}`}>
                    Training Goal
                  </span>
                  <span className="text-xs text-gray-500 text-center">
                    Milestone or prep race
                  </span>
                </button>
              </div>

              {/* Info Box */}
              <div className={`${
                goalType === 'primary' 
                  ? 'bg-orange-50/50 text-orange-800' 
                  : 'bg-blue-50/50 text-blue-800'
                } rounded-xl p-4`}
              >
                <p className="text-sm">
                  {goalType === 'primary' 
                    ? "This is your main race or event. Your training plan will be optimized around this goal."
                    : "Training goals help track your progress and prepare for your main race. Perfect for tune-up races or milestones."
                  }
                </p>
              </div>

              <GoalForm 
                onSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                type={goalType}
              />
            </div>
          </div>
        </div>
      </main>

      <BottomNav />
    </div>
  );
};

export default AddGoalPage;
