import React from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDistanceFromMeters } from '../../utils/activityUtils.ts';
import {useGoals} from "../../hooks/useGoals.ts";

const GoalList: React.FC = () => {
  const navigate = useNavigate();
  const { data: goals, isLoading } = useGoals();

  if (isLoading) {
    return (
      <div className="space-y-2 p-2 animate-pulse">
        {[1, 2, 3].map((i) => (
          <div key={i} className="rounded-xl p-3 shadow-sm border border-gray-100 bg-white">
            <div className="flex items-center justify-between">
              <div className="space-y-2 w-full">
                <div className="flex items-center gap-1.5">
                  <div className="h-5 w-16 bg-orange-100 rounded-full" />
                  <div className="h-5 w-12 bg-orange-50 rounded-full" />
                </div>
                <div className="h-5 w-3/4 bg-gray-100 rounded-lg" />
                <div className="flex gap-3">
                  <div className="h-4 w-16 bg-gray-100 rounded" />
                  <div className="h-4 w-20 bg-gray-100 rounded" />
                  <div className="h-4 w-24 bg-gray-100 rounded" />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (!goals?.length) {
    return (
      <div className="p-4 text-center">
        <div className="w-12 h-12 mx-auto mb-3 bg-orange-100 rounded-full flex items-center justify-center">
          <svg className="w-6 h-6 text-orange-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
          </svg>
        </div>
        <h3 className="text-base font-semibold text-gray-900 mb-1">No Goals Set</h3>
        <p className="text-sm text-gray-500 mb-4">Set your first running goal and let Ronny help you achieve it!</p>
        <button
          onClick={() => navigate('/add-goal')}
          className="inline-flex items-center px-3 py-1.5 rounded-full bg-orange-500 text-sm text-white font-medium hover:bg-orange-600 transition-colors"
        >
          Add Your First Goal
        </button>
      </div>
    );
  }

  // Sort goals: Primary goals first, then by date in ascending order
  const sortedGoals = [...goals].sort((a, b) => {
    return new Date(a.date).getTime() - new Date(b.date).getTime();
  });

  return (
    <div className="space-y-2 p-2">
      {sortedGoals.map((goal) => (
        <div 
          key={goal.id}
          className={`
            rounded-xl p-3 shadow-sm border transition-all duration-200
            ${goal.type === 'primary'
              ? 'bg-orange-50/50 border-orange-200'
              : 'bg-white border-gray-100'
            }
          `}
        >
          <div className="flex items-center justify-between">
            <div>
              <div className="flex items-center gap-1.5 mb-0.5">
                <span className={`
                  text-xs font-medium px-1.5 py-0.5 rounded-full
                  ${goal.type === 'primary'
                    ? 'bg-orange-100 text-orange-700'
                    : 'bg-blue-100 text-blue-700'
                  }
                `}>
                  {goal.type === 'primary' ? '🎯 Race' : '🎪 Training'}
                </span>
                <span className={`
                  text-xs font-medium
                  ${goal.type === 'primary' ? 'text-orange-600' : 'text-blue-600'}
                `}>
                  {getDaysUntil(goal.date)}
                </span>
              </div>
              <h3 className={`text-sm font-semibold mb-0.5
                ${goal.type === 'primary' ? 'text-orange-900' : 'text-gray-900'}
              `}>
                {goal.name}
              </h3>
              <div className="flex gap-3 text-xs text-gray-500">
                {goal.distance && (
                  <span>{formatDistanceFromMeters(goal.distance * 1000)} km</span>
                )}
                {goal.targetTime && (
                  <span>{goal.targetTime}</span>
                )}
                <span>{new Date(goal.date).toLocaleDateString()}</span>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

// Helper function to show relative time
const getDaysUntil = (date: string): string => {
  const days = Math.ceil((new Date(date).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24));
  if (days === 0) return 'Today';
  if (days === 1) return 'Tomorrow';
  if (days < 0) return 'Past';
  if (days <= 7) return `${days} days`;
  if (days <= 30) return `${Math.ceil(days / 7)} weeks`;
  return `${Math.ceil(days / 30)} months`;
};

export default GoalList; 
