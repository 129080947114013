import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '../services/api.ts';

export function useTrainingDays() {
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: ['trainingDays'],
    queryFn: api.settings.getTrainingDays,
    staleTime: 1000 * 60 * 5,
  });

  const updateTrainingDays = useMutation({
    mutationFn: api.settings.updateTrainingDays,
    onSuccess: () => queryClient.invalidateQueries(['trainingDays']),
  });

  return {
    ...query,
    updateTrainingDays: updateTrainingDays.mutateAsync,
  };
} 
