import React from 'react';
import { useNavigate } from 'react-router-dom';
import ronnyLogo from '../../assets/coach/thumbs_up.png';
import {useUser} from "../../hooks/useUser.ts";
import Notifications from './Notifications.tsx';

interface HeaderProps {
  title: string;
  showBack?: boolean;
  showProfile?: boolean;
}

const Header: React.FC<HeaderProps> = ({ 
  title, 
  showBack = false,
  showProfile = true 
}) => {
  const navigate = useNavigate();
  const { data: userDetails } = useUser();

  return (
    <header className="fixed top-0 inset-x-0 bg-white/80 backdrop-blur-sm border-b border-gray-100 z-40 w-full" style={{ paddingTop: 'env(safe-area-inset-top)' }}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 h-16 flex items-center justify-between">
        {/* Left side - Logo and/or back button */}
        <div className="flex items-center space-x-3">
          {showBack ? (
            <button 
              onClick={() => navigate(-1)}
              className="p-2 rounded-full hover:bg-gray-100/80 transition-colors duration-200"
            >
              <svg 
                className="w-5 h-5 text-gray-700" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2.5} 
                  d="M15 19l-7-7 7-7" 
                />
              </svg>
            </button>
          ) : (
            <img src={ronnyLogo} alt="GoRonny" className="h-8 w-8" />
          )}
        </div>

        {/* Center - Title */}
        <div className="flex flex-col items-center">
          <h1 className="text-lg font-semibold bg-gradient-to-r from-gray-900 to-gray-700 bg-clip-text text-transparent">
            {title || 'GoRonny'}
          </h1>
          {showBack && (
            <span className="text-xs text-gray-500">
              Back to previous
            </span>
          )}
        </div>

        {/* Right side - Actions */}
        <div className="flex items-center space-x-3">
          <Notifications />

          {showProfile && userDetails?.profile && (
            <div className="relative">
              <img 
                src={userDetails.profile} 
                alt="Profile" 
                className="w-9 h-9 rounded-full ring-2 ring-white shadow-sm object-cover"
              />
              <div className="absolute bottom-0 right-0 w-2.5 h-2.5 bg-green-400 rounded-full ring-2 ring-white" />
            </div>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
