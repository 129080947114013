import React, { useState } from 'react';
import stretchingRonny from '../assets/coach/stretching.png';
import { authService } from '../services/auth.service.ts';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [credentials, setCredentials] = useState({ username: '', password: '', remember_me: false });
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(null);
        setIsLoading(true);

        try {
            await authService.login(credentials.username, credentials.password, credentials.remember_me);
            navigate('/');
        } catch (err) {
            setError('Invalid credentials. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-orange-500 to-orange-400 p-6">
            {/* Logo and Mascot Container */}
            <div className="w-full max-w-md flex flex-col items-center mb-8">
                <div className="relative w-40 h-40 mb-2">
                    <img
                        src={stretchingRonny}
                        alt="Ronny Stretching"
                        className="w-full h-full object-contain drop-shadow-xl animate-stretch"
                        style={{
                            transformOrigin: 'bottom center',
                            animation: 'stretch 3s ease-in-out infinite'
                        }}
                    />
                </div>
                <h1 className="text-4xl font-extrabold text-white mt-2 tracking-tight animate-fade-in">
                    Go<span className="text-orange-800">Ronny</span>
                </h1>
                <p className="text-white/90 mt-2 text-center text-lg font-medium animate-fade-in-delay">
                    Your friendly running companion! 🏃‍♂️
                </p>
            </div>

            {/* Login Card */}
            <div className="w-full max-w-md space-y-8">
                <div className="bg-white rounded-2xl shadow-lg p-8">
                    <h2 className="text-2xl font-bold text-gray-900 mb-6 text-center">Welcome Back!</h2>

                    {error && (
                        <div className="mb-4 p-4 bg-red-50 border border-red-100 rounded-xl text-red-600 text-sm">
                            {error}
                        </div>
                    )}

                    <form onSubmit={handleLogin} className="space-y-4">
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Username
                            </label>
                            <input
                                type="text"
                                value={credentials.username}
                                onChange={(e) => setCredentials(prev => ({ ...prev, username: e.target.value }))}
                                className="w-full px-4 py-2 rounded-xl border border-gray-200"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                                Password
                            </label>
                            <input
                                type="password"
                                value={credentials.password}
                                onChange={(e) => setCredentials(prev => ({ ...prev, password: e.target.value }))}
                                className="w-full px-4 py-2 rounded-xl border border-gray-200"
                                required
                            />
                        </div>
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                id="remember-me"
                                checked={credentials.remember_me}
                                onChange={(e) => setCredentials(prev => ({ ...prev, remember_me: e.target.checked }))}
                                className="h-4 w-4 text-orange-500 focus:ring-orange-500 border-gray-300 rounded"
                            />
                            <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-700">
                                Remember me
                            </label>
                        </div>
                        <button
                            type="submit"
                            disabled={isLoading}
                            className="w-full py-3 px-4 bg-gradient-to-r from-orange-500 to-orange-600
                       hover:from-orange-600 hover:to-orange-700 text-white rounded-xl
                       font-medium transition-all duration-200 shadow-sm hover:shadow
                       disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            {isLoading ? 'Logging in...' : 'Login'}
                        </button>
                    </form>
                </div>
            </div>

            {/* Terms and Privacy */}
            <p className="text-white/80 text-sm mt-8 text-center animate-fade-in-delay-2">
                By continuing, you agree to our{' '}
                <a href="/terms" className="underline hover:text-white transition-colors">Terms</a> and{' '}
                <a href="/privacy" className="underline hover:text-white transition-colors">Privacy Policy</a>
            </p>
        </div>
    );
};

export default Login;
