import React from 'react';
import { CompletedActivity, PlannedActivity } from '../../../types/activity';
import { formatDistanceFromMeters, formatPace, formatDuration } from '../../../utils/activityUtils.ts';
import ActivityBlocksGraph from './ActivityBlocksGraph.tsx';

interface ShareableActivityProps {
  plannedActivity?: PlannedActivity;
  completedActivity?: CompletedActivity;
}

const ShareableActivity: React.FC<ShareableActivityProps> = ({
  plannedActivity,
  completedActivity
}) => {
  return (
    <div 
      id="shareable-activity" 
      className="w-[1080px] h-[1080px] bg-orange-500 p-12 text-white relative"
    >
      {/* Header */}
      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-4xl font-bold mb-2">
            {completedActivity?.type || plannedActivity?.type}
          </h1>
          <p className="text-xl text-white/80">
            {new Date(completedActivity?.date || plannedActivity?.date).toLocaleDateString(undefined, {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })}
          </p>
        </div>
        <div className="text-right">
          <div className="text-5xl font-bold mb-2">
            {formatDistanceFromMeters((completedActivity || plannedActivity).blocks.reduce((total, block) => 
              total + (block.distance * 1000), 0))}
          </div>
          <div className="text-2xl text-white/80">kilometers</div>
        </div>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-3 gap-6 mb-8">
        <div className="bg-white/10 rounded-2xl p-6">
          <div className="text-lg text-white/80 mb-1">Average Pace</div>
          <div className="text-3xl font-bold">
            {formatPace(completedActivity?.actualPace || plannedActivity?.targetPace)}/km
          </div>
        </div>
        <div className="bg-white/10 rounded-2xl p-6">
          <div className="text-lg text-white/80 mb-1">Duration</div>
          <div className="text-3xl font-bold">
            {formatDuration(completedActivity?.totalTime || plannedActivity?.estimatedDuration)}
          </div>
        </div>
        <div className="bg-white/10 rounded-2xl p-6">
          <div className="text-lg text-white/80 mb-1">Type</div>
          <div className="text-3xl font-bold capitalize">
            {completedActivity?.type || plannedActivity?.type}
          </div>
        </div>
      </div>

      {/* Activity Blocks */}
      <div className="bg-white rounded-2xl p-6">
        <ActivityBlocksGraph 
          plannedBlocks={plannedActivity?.blocks}
          completedBlocks={completedActivity?.blocks}
        />
      </div>

      {/* Simplified Footer */}
      <div className="absolute bottom-12 left-12 right-12">
        <div className="border-t border-white/20 pt-8">
          <div className="flex items-center justify-between">
            <div>
              <div className="text-3xl font-bold">Run with Ronny</div>
              <div className="text-lg text-white/80">
                Trained with Ronny - Your Virtual Running Coach
              </div>
            </div>
            <div className="text-xl text-white/80">
              {new Date().toLocaleDateString()}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareableActivity; 