import React from 'react';
import CoachMessage from './components/CoachMessage.tsx';
import TrainingCalendar from './components/TrainingCalendar.tsx';
import { CompletedActivity, PlannedActivity } from '../../types/activity';
import GoalList from '../Goals/GoalList.tsx';
import PageLayout from '../shared/PageLayout.tsx';
import ErrorScreen from '../shared/ErrorScreen.tsx';
import OnboardingFlow from '../Onboarding/OnboardingFlow.tsx';
import {useCompletedActivities, usePlannedActivities} from "../../hooks/UseActivities.ts";
import {useUser} from "../../hooks/useUser.ts";
import RacePredictions from './components/RacePredictions.tsx';

const Dashboard: React.FC = () => {
  const {data: plannedActivities, isLoading: isPlannedActivitiesLoading } = usePlannedActivities();
  const {data: activities, isLoading: isCompletedActivitiesLoading } = useCompletedActivities();
  const {data: userDetails, isLoading: isUserLoading, error } = useUser();

  if (error) {
    return <ErrorScreen error={error} />;
  }

  if (!isUserLoading && !userDetails?.hasCompletedSetup) return <OnboardingFlow />;

  return (
    <PageLayout>
      {/* Coach Message */}
      <div className="bg-gradient-to-r from-orange-500 to-orange-400 rounded-2xl shadow-xl overflow-hidden">
        {isUserLoading ? (
          <div className="p-6 sm:p-8 animate-pulse">
            <div className="flex items-start space-x-6">
              <div className="w-20 h-20 bg-white/20 rounded-2xl" />
              <div className="flex-1 space-y-3">
                <div className="h-8 bg-white/20 rounded-lg w-3/4" />
                <div className="h-4 bg-white/20 rounded w-1/2" />
              </div>
            </div>
          </div>
        ) : (
          <CoachMessage 
            user={userDetails}
            plannedActivities={plannedActivities}
          />
        )}
      </div>

      <div className='grid grid-cols-1 lg:grid-cols-3 gap-6'>
        <div className='lg:col-span-2 space-y-6'>
          <RacePredictions />
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2 space-y-6">
          {/* Goals Section */}
          <div className="bg-white rounded-2xl shadow-sm border border-orange-100 overflow-hidden">
            <div className="border-b border-orange-100 px-6 py-4">
              <h2 className="text-lg font-semibold text-gray-900">Your Goals</h2>
            </div>
            <GoalList />
          </div>

          {/* Calendar Section */}
          <div className="bg-white rounded-2xl shadow-sm border border-orange-100 overflow-hidden">
            <div className="p-6">
              <h2 className="text-lg font-semibold text-gray-900 mb-4">Training Calendar</h2>
              {isCompletedActivitiesLoading || isPlannedActivitiesLoading ? (
                <div className="animate-pulse">
                  <div className="grid grid-cols-7 gap-2">
                    {[...Array(35)].map((_, i) => (
                      <div key={i} className="aspect-square rounded-xl bg-gray-100" />
                    ))}
                  </div>
                </div>
              ) : (
                <TrainingCalendar 
                  activities={activities as CompletedActivity[]}
                  plannedActivities={plannedActivities as PlannedActivity[]}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default Dashboard;
