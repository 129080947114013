import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getWeekNumber } from '../../utils/activityUtils.ts';
import {useWeekPlan} from "../../hooks/useWeekPlan.ts";

const DesktopNav: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { data: weekPlan } = useWeekPlan();

  const getCurrentWeekId = () => {
    const now = new Date();
    const currentWeekNumber = getWeekNumber(now);
    const currentYear = now.getFullYear();
    
    return weekPlan?.find(week => 
      week.week_number === currentWeekNumber && 
      week.year === currentYear
    )?.id;
  };

  const isActive = (path: string) => {
    if (path === '/') {
      return location.pathname === path;
    }
    return location.pathname.startsWith(path);
  };

  return (
    <div className="hidden sm:flex flex-col space-y-2 fixed left-6 top-1/2 -translate-y-1/2">
      <button 
        onClick={() => navigate('/')}
        className={`p-3 rounded-xl transition-all duration-200 hover:bg-orange-50
          ${isActive('/') ? 'bg-orange-50 text-orange-600' : 'text-gray-400'}`}
        title="Dashboard"
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" 
          />
        </svg>
      </button>

      <button 
        onClick={() => navigate(`/week/${getCurrentWeekId()}`)}
        className={`p-3 rounded-xl transition-all duration-200 hover:bg-orange-50
          ${isActive('/week') ? 'bg-orange-50 text-orange-600' : 'text-gray-400'}`}
        title="Training Plan"
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" 
          />
        </svg>
      </button>

      <button 
        onClick={() => navigate('/settings')}
        className={`p-3 rounded-xl transition-all duration-200 hover:bg-orange-50
          ${isActive('/settings') ? 'bg-orange-50 text-orange-600' : 'text-gray-400'}`}
        title="Settings"
      >
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} 
            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
          />
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
        </svg>
      </button>
    </div>
  );
};

export default DesktopNav; 
