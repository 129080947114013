import React from 'react';
import Header from './Header.tsx';
import BottomNav from './BottomNav.tsx';
import DesktopNav from './DesktopNav.tsx';

interface PageLayoutProps {
  children: React.ReactNode;
  title: string;
  showBack?: boolean;
  showProfile?: boolean;
}

const PageLayout: React.FC<PageLayoutProps> = ({ 
  children, 
  title, 
  showBack = false,
  showProfile = true 
}) => {
  return (
    <div className="min-h-screen bg-orange-50">
      <div className="fixed top-0 left-0 right-0 bg-orange-500 h-[env(safe-area-inset-top)]" />
      
      <div className="relative min-h-screen">
        <Header 
          title={title} 
          showBack={showBack}
          showProfile={showProfile}
        />
        <DesktopNav />
        <main className="flex-1 container mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 sm:pl-20">
          <div className="pt-[calc(4rem+env(safe-area-inset-top)+1.5rem)] pb-[calc(5rem+env(safe-area-inset-bottom))] space-y-6">
            {children}
          </div>
        </main>

        <BottomNav />
      </div>
    </div>
  );
};

export default PageLayout; 
