import { useQuery } from '@tanstack/react-query';
import { api } from '../services/api.ts';

export enum WeekPlanPhase {
    BASE = 'base',
    BUILD = 'build-up',
    PEAK = 'peak',
    TAPER = 'taper',
    RACEWEEK = 'race week',
  }
  
export interface WeekPlan {
    id: string;
    week_number: number;
    year: number;
    target_distance: number;
    phase: WeekPlanPhase;
    focus: string;
    notes: string;
    keyWorkouts: string[];
    feedback?: string;
}

export function useWeekPlan() {
  return useQuery<WeekPlan[]>({
    queryKey: ['weekPlan'],
    queryFn: () => api.weekPlan.getWeekPlan(),
    staleTime: 1000 * 60 * 5,
  });
} 
