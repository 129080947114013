import { useState } from 'react';
import { useParams } from 'react-router-dom';
import thumbsUpCoach from '../assets/coach/thumbs_up.png';
import twoThumbsUpCoach from '../assets/coach/two_thumbs_up.png';
import runningCoach from '../assets/coach/running.png';
import SectionHeader from '../components/shared/SectionHeader.tsx';
import { api } from '../services/api.ts';
import PageLayout from '../components/shared/PageLayout.tsx';
import ActivityBlocksGraph from '../components/Activities/components/ActivityBlocksGraph.tsx';
import { formatDistanceFromMeters, formatPace, formatDuration } from '../utils/activityUtils.ts';
import { useCompletedActivities, usePlannedActivities } from "../hooks/UseActivities.ts";
import ShareButton from '../components/Activities/components/ShareButton.tsx';
import ShareableActivity from '../components/Activities/components/ShareableActivity.tsx';
import RegenerateFeedback from '../components/Activities/components/RegenerateFeedback.tsx';

const ActivityDetails = () => {
  const [isSyncing, setIsSyncing] = useState(false);
  const [isSkipping, setIsSkipping] = useState(false);
  const { date } = useParams();
  const { data: activities, refetch: refreshActivities, isLoading: isLoadingActivities } = useCompletedActivities();
  const { data: plannedActivities, refetch: refreshPlannedActivities, isLoading: isLoadingPlannedActivities } = usePlannedActivities();

  const pastActivity = activities?.find(a => {
    const activityDate = new Date(a.date).toISOString().split('T')[0];
    return activityDate === date;
  });

  const plannedActivity = plannedActivities?.find(a => a.date === date);

  const handleSync = async () => {
    try {
      setIsSyncing(true);
      await api.activities.syncActivities();
      await refreshActivities();
      await refreshPlannedActivities();
      setIsSyncing(false);
    } catch (error) {
      setIsSyncing(false);
      console.error('Failed to sync activity:', error);
    }
  };

  const handleSkip = async () => {
    try {
      setIsSkipping(true);
      await api.activities.markActivitySkipped(plannedActivity.id);
      await refreshPlannedActivities();
      setIsSkipping(false);
    } catch (error) {
      setIsSkipping(false);
      console.error('Failed to mark activity as skipped:', error);
    }
  };

  const getCoachImage = (feedback) => {
    if (!feedback) return thumbsUpCoach;
    if (feedback.overallFeedback?.includes('Great')) return twoThumbsUpCoach;
    return runningCoach;
  };

  const [isFeedbackLoading, setIsFeedbackLoading] = useState(false);
  const [isFeedbackSuccess, setIsFeedbackSuccess] = useState(false);

  const regenerateFeedback = async (reason) => {
    setIsFeedbackLoading(true);
    await api.activities.regenerateActivity(plannedActivity.id, reason);
    await refreshPlannedActivities();
    setIsFeedbackLoading(false);
    setIsFeedbackSuccess(true);
  };

  if (isLoadingActivities || isLoadingPlannedActivities) {
    return (
      <PageLayout
        title={new Date(date).toLocaleDateString(undefined, { weekday: 'long', month: 'long', day: 'numeric' })}
        showBack
      >
        <div className="space-y-4 pb-20">
          {/* Loading Overview */}
          <div className="bg-gradient-to-br from-orange-500 to-orange-600 rounded-2xl p-6 text-white shadow-lg animate-pulse">
            <div className="flex items-center justify-between mb-6">
              <div className="flex items-center space-x-4">
                <div className="p-3 bg-white/10 rounded-xl w-12 h-12" />
                <div>
                  <div className="h-6 w-32 bg-white/10 rounded-lg mb-2" />
                  <div className="h-4 w-48 bg-white/10 rounded-lg" />
                </div>
              </div>
            </div>

            {/* Loading Stats */}
            <div className="grid grid-cols-2 sm:grid-cols-4 gap-3">
              {[1, 2, 3, 4].map((i) => (
                <div key={i} className="bg-white/10 rounded-xl p-3">
                  <div className="h-4 w-16 bg-white/20 rounded mb-1" />
                  <div className="h-6 w-24 bg-white/20 rounded" />
                </div>
              ))}
            </div>
          </div>

          {/* Loading Content Blocks */}
          {[1, 2].map((i) => (
            <div key={i} className="bg-white rounded-2xl shadow-sm overflow-hidden border border-gray-100">
              <div className="p-6 animate-pulse">
                <div className="flex items-center justify-between mb-4">
                  <div className="h-6 w-48 bg-gray-200 rounded" />
                  <div className="h-10 w-10 bg-gray-200 rounded-full" />
                </div>
                <div className="space-y-4">
                  <div className="h-24 bg-gray-100 rounded-xl" />
                  <div className="h-24 bg-gray-100 rounded-xl" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </PageLayout>
    );
  }

  return (
    <PageLayout
      title={new Date(date).toLocaleDateString(undefined, { weekday: 'long', month: 'long', day: 'numeric' })}
      showBack
    >
      <div className="pb-20">
        {!plannedActivity && !pastActivity ? (
          <div className="bg-white/80 backdrop-blur-sm rounded-2xl p-8 shadow-sm border border-gray-100 text-center">
            <div className="w-16 h-16 mx-auto mb-4 bg-gray-100 rounded-full flex items-center justify-center">
              <svg className="w-8 h-8 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
            <h3 className="text-lg font-medium text-gray-900 mb-1">No Activities Found</h3>
            <p className="text-gray-500">There are no activities scheduled or completed for this date.</p>
          </div>
        ) : (
          <div className="space-y-4">
            {/* Overview Section */}
            <div className="bg-gradient-to-br from-orange-500 to-orange-600 rounded-2xl p-6 text-white shadow-lg">
              <div className="flex items-center justify-between mb-6">
                <div className="flex items-center space-x-4">
                  <div className="p-3 bg-white/10 rounded-xl">
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                        d="M13 10V3L4 14h7v7l9-11h-7z" />
                    </svg>
                  </div>
                  <div>
                    <h2 className="text-xl font-semibold">
                      {plannedActivity?.type || pastActivity?.type}
                    </h2>
                    <p className="text-white/80">
                      {new Date(date).toLocaleDateString(undefined, {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                      })}
                    </p>
                  </div>
                </div>

                {/* Action Buttons */}
                {plannedActivity && !pastActivity && plannedActivity.status !== 'skipped' && new Date(date) <= new Date() && (
                  <div className="flex space-x-2">
                    <button
                      onClick={handleSkip}
                      disabled={isSkipping}
                      className={`px-4 py-2 rounded-xl text-sm font-medium transition-all
                        ${isSkipping
                          ? 'bg-white/20 cursor-not-allowed'
                          : 'bg-white/10 hover:bg-white/20'
                        }`}
                    >
                      {isSkipping ? 'Marking as skipped...' : 'Mark as Skipped'}
                    </button>
                    <button
                      onClick={handleSync}
                      disabled={isSyncing}
                      className={`px-4 py-2 rounded-xl text-sm font-medium bg-white text-orange-600 transition-all
                        ${isSyncing
                          ? 'opacity-50 cursor-not-allowed'
                          : 'hover:bg-white/90'
                        }`}
                    >
                      {isSyncing ? 'Syncing...' : 'Sync Now'}
                    </button>
                  </div>
                )}

                {/* Add Share Button when we have a completed activity */}
                {pastActivity && (
                  <ShareButton
                    onBeforeShare={() => {
                      // The shareable activity component should already be in the DOM but hidden
                      const shareableActivity = document.getElementById('shareable-activity');
                      if (shareableActivity) {
                        shareableActivity.style.display = 'block';
                      }
                    }}
                    onAfterShare={() => {
                      const shareableActivity = document.getElementById('shareable-activity');
                      if (shareableActivity) {
                        shareableActivity.style.display = 'none';
                      }
                    }}
                  />
                )}
              </div>

              {/* Quick Stats */}
              <div className="grid grid-cols-2 sm:grid-cols-4 gap-3">
                <div className="bg-white/10 rounded-xl p-3">
                  <div className="text-sm text-white/80 mb-1">Distance</div>
                  <div className="text-lg font-semibold">
                    {formatDistanceFromMeters((plannedActivity || pastActivity).blocks.reduce((total, block) =>
                      total + (block.distance * 1000), 0))}
                  </div>
                </div>
                <div className="bg-white/10 rounded-xl p-3">
                  <div className="text-sm text-white/80 mb-1">Type</div>
                  <div className="text-lg font-semibold capitalize">
                    {(plannedActivity || pastActivity).type}
                  </div>
                </div>
                {pastActivity && (
                  <>
                    <div className="bg-white/10 rounded-xl p-3">
                      <div className="text-sm text-white/80 mb-1">Avg Pace</div>
                      <div className="text-lg font-semibold">
                        {formatPace(pastActivity.actualPace)}/km
                      </div>
                    </div>
                    <div className="bg-white/10 rounded-xl p-3">
                      <div className="text-sm text-white/80 mb-1">Duration</div>
                      <div className="text-lg font-semibold">
                        {formatDuration(pastActivity.totalTime)}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* Coach Feedback */}
            {plannedActivity?.feedback && (
              <div className="bg-white rounded-2xl shadow-sm overflow-hidden border border-gray-100">
                <SectionHeader
                  title="Coach Feedback"
                  badge={{
                    text: plannedActivity.feedback.performance_rating || 'Feedback',
                    color: 'purple'
                  }}
                  action={
                    <div className="bg-white p-1 rounded-full shadow-md">
                      <img
                        src={getCoachImage(plannedActivity.feedback)}
                        alt="Coach"
                        className="w-10 h-10 rounded-full"
                      />
                    </div>
                  }
                />
                <div className="p-6 space-y-4">
                  {/* Main Message */}
                  {plannedActivity.feedback.performance_rating && (
                    <div className="flex items-start space-x-4">
                      <div className="flex-1">
                        <div className="bg-gradient-to-r from-blue-50 to-purple-50 p-4 rounded-xl shadow-sm">
                          <p className="text-gray-800">{plannedActivity.feedback.performance_rating}</p>
                        </div>
                      </div>
                    </div>
                  )}

                  {/* Pace Analysis */}
                  {plannedActivity.feedback.message && (
                    <div className="bg-orange-50 rounded-xl p-4">
                      <div className="flex items-center space-x-2 mb-2">
                        <svg className="w-5 h-5 text-orange-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                            d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <h3 className="text-sm font-medium text-gray-900">Pace Analysis</h3>
                      </div>
                      <p className="text-sm text-gray-600">{plannedActivity.feedback.message}</p>
                    </div>
                  )}

                  {/* Pro Tip */}
                  {plannedActivity.feedback.tip && (
                    <div className="bg-yellow-50 rounded-xl p-4">
                      <div className="flex items-center space-x-2 mb-2">
                        <svg className="w-5 h-5 text-yellow-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                            d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
                          />
                        </svg>
                        <h3 className="text-sm font-medium text-gray-900">Pro Tip</h3>
                      </div>
                      <p className="text-sm text-gray-600">{plannedActivity.feedback.tip}</p>
                    </div>
                  )}
                </div>
              </div>
            )}

            {/* Activity Comparison Graph */}
            {(plannedActivity || pastActivity) && (
              <div className="bg-white rounded-2xl shadow-sm overflow-hidden border border-gray-100 p-6">
                <h3 className="text-base font-medium text-gray-900 mb-4">Activity Comparison</h3>
                <ActivityBlocksGraph
                  plannedBlocks={plannedActivity?.blocks || []}
                  completedBlocks={pastActivity?.blocks || []}
                />
                {plannedActivity?.blocks && !pastActivity && (
                  <RegenerateFeedback 
                    onConfirm={async (reason) => {
                      // Handle regeneration logic here
                      console.log('Regenerating feedback...');
                      await regenerateFeedback(reason);
                    }}
                    onClose={() => {
                      console.log('Closing feedback...');
                    }} 
                    isLoading={isFeedbackLoading}
                    isSuccess={isFeedbackSuccess}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>

      {/* Hidden Shareable Activity Component */}
      <div className="hidden fixed left-0 top-0">
        <ShareableActivity
          plannedActivity={plannedActivity}
          completedActivity={pastActivity}
        />
      </div>
    </PageLayout>
  );
};

export default ActivityDetails;
