import { TrainingBlock, TrainingBlockType } from '../types/activity';

export const getCompletedBlockType = (block: TrainingBlock, defaultPace: string) => {
  // If block has a type, use it
  if (block.type) {
    return block.type;
  }

  // Otherwise, infer type based on pace comparison
  const blockPaceNum = parseFloat(block.pace);
  const defaultPaceNum = parseFloat(defaultPace);

  if (blockPaceNum < defaultPaceNum * 0.9) {
    return 'interval' as TrainingBlockType;
  } else if (blockPaceNum > defaultPaceNum * 1.1) {
    return 'cooldown' as TrainingBlockType;
  } else {
    return 'main' as TrainingBlockType;
  }
}; 

export const formatPace = (pace: string | number): string => {
  const paceNum = Number(pace);
  const minutes = Math.floor(paceNum);
  const seconds = Math.round((paceNum - minutes) * 60);
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

export const formatDistanceFromMeters = (meters) => {
    const kilometers = (meters / 1000);
    
    return formatDistance(kilometers);
  };

  export const formatDistance = (distance: number): string => {
    return `${Number(distance).toFixed(1)}`;
  };
  
  export const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('nl-BE', {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
    });
  };
  
  export const getActivityDates = (activities) => {
    return activities.map(activity => 
      new Date(activity.startDateLocal).getDate()
    );
  };
  
  export const isToday = (dateString) => {
    const today = new Date();
    const activityDate = new Date(dateString);
    return (
      today.getDate() === activityDate.getDate() &&
      today.getMonth() === activityDate.getMonth() &&
      today.getFullYear() === activityDate.getFullYear()
    );
  };
  
  export const getNextTraining = (plannedActivities) => {
    if (!plannedActivities?.length) return null;
    return plannedActivities.sort((a, b) => 
      new Date(a.date) - new Date(b.date)
    )[0];
  };
  
  export const getWeekNumber = (date: Date) => {
    const startOfYear = new Date(date.getFullYear(), 0, 1);
    const daysSinceStartOfYear = Math.floor((date - startOfYear) / (1000 * 60 * 60 * 24));
    return Math.ceil((daysSinceStartOfYear + startOfYear.getDay()) / 7);
  };

  // Duration is minutes, decimal, we need seconds too. If duration is longer than 60 minutes, we need hours too. 
  // Also add a 0 if seconds is less than 10
  export const formatDuration = (duration: number): string => {
    const hours = Math.floor(duration / 60);
    const minutes = Math.floor(duration % 60);
    const seconds = Math.round((duration - minutes) * 60);
    if(hours > 0) {
      return `${hours}:${minutes}:${seconds.toString().padStart(2, '0')}`;
    } else if(minutes > 0) {
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    } else {
      return `${seconds} seconds`;
    }
  };