import { useQuery } from '@tanstack/react-query';
import { api } from '../services/api.ts';

interface RacePredictions {
  time5K: number;
  time10K: number;
  timeHalfMarathon: number;
  timeMarathon: number;
}

export function useRacePredictions() {
  return useQuery<RacePredictions>({
    queryKey: ['racePredictions'],
    queryFn: () => api.predictions.getRacePredictions(),
    staleTime: 1000 * 60 * 5,
  });
} 